
import {Component} from 'vue-property-decorator';
import Vue from 'vue';
import MicroService from "@/models/MicroService";
import MicroserviceRepository from "@/api/repositories/MicroserviceRepository";
import {SubscriptionStatus} from "@/enum/SubscriptionStatus.enum";

@Component({
  components: {
    MicroServiceCard: () => import(
        /* webpackChunkName: "MicroServiceCard" */
        '@/components/MicroServices/MicroServiceCard.component.vue'
        ),
    MicroServiceHeaderCard: () => import(
        /* webpackChunkName: "MicroServiceHeaderCard" */
        '@/components/MicroServices/MicroServiceHeaderCard.component.vue'
        ),
    MicroServiceDetailCard: () => import(
        /* webpackChunkName: "MicroServiceDetailCard" */
        '@/components/MicroServices/BuyMicroService.component.vue'
        ),
    QuitMicroServiceCardComponent: () => import(
        /* webpackChunkName: "QuitMicroServiceCardComponent" */
        '@/components/MicroServices/QuitMicroService.component.vue'
        ),
    ResubscribeServiceCardComponent: () => import(
        /* webpackChunkName: "ResubscribeServiceCardComponent" */
        '@/components/MicroServices/ResubscribeMicroService.component.vue'
        ),
    StripeConnectActivateComponent: () => import(
        /* webpackChunkName: "StripeConnectActivateComponent" */
        '@/components/Payment/StripeConnectActivate.component.vue'
        ),
  }
})
export default class MicroServicesOverview extends Vue {
  /**
   * service that shown inside this summary view
   * @private
   */
  private service!: MicroService;

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading = false;

  /**
   * flag that indicates if loading service has failed
   * @private
   */
  private hasError = false;

  private showActiveStripe = false;

  /**
   * flag that handles the detail modal
   * @private
   */
  private showBuyCard: boolean = false;

  /**
   * flag that indicates if the quit modal is shown
   * @private
   */
  private showQuitCard: boolean = false;

  /**
   * flag that indicates if the resubscribe modal is shown
   * @private
   */
  private showResubscribeCard: boolean = false;

  private get description() {
    return this.service.description?.replaceAll('\\n', '<br>').toString();
  }

  /**
   * loads the microservice by the id, we need to do it this way, because if we just pass the micro service via
   * route params, we wouldn't be able to show data on reload
   */
  async created() {
    try {
      this.isLoading = true;
      if(!this.$route.params.id || !this.$route.params.microserviceId) {
        this.hasError = true;
        this.isLoading = false;
        return this.$router.back();
      }

      const result = await MicroserviceRepository.getById(this.$route.params.id, this.$route.params.microserviceId);
      this.service = MicroService.parseFromObject(result.data);
    } catch(_) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
    }
  }

  private onPayWithMerwaysActivated() {
    this.showActiveStripe = true;
  }

  /**
   * goes back to previous page
   * @private
   */
  private onBackPressed() {
    this.$router.go(-1);
  }

  /**
   * returns the headline of the view based on the current state
   * @private
   */
  private get headlineByState() {
    if(this.isLoading) return this.$t('GENERAL.LOADING_DATA');
    if(this.hasError) return this.$t('MICRO_SERVICES.LOADING_MICROSERVICE_FAILED');
    return this.service.title;
  }

  /**
   * returns the price with the price type in format
   * @private
   */
  private get priceInfo() {
    return this.$t('MICRO_SERVICES.PRICE_TYPE.'+ this.service.priceType,
        {'amount': this.$formatCurrency(this.service.servicePrice)});
  }

  /**
   * gets the text of the button based on the subscriptionStatus of the service
   * @private
   */
  private get textByStatus() {
    switch(this.service.subscriptionStatus) {
      case SubscriptionStatus.NOT_SUBSCRIBED:
        return this.$t('GENERAL.BUY');
      case SubscriptionStatus.SUBSCRIBED:
        return this.$t('GENERAL.QUIT');
      case SubscriptionStatus.CANCELLED:
        return this.$t('GENERAL.RESUBSCRIBE');
    }
  }

  /**
   * is called when the user clicks on a card, opens detail modal with the clicked card
   * @private
   */
  private onServiceClicked() {
    switch(this.service.subscriptionStatus) {
      case SubscriptionStatus.NOT_SUBSCRIBED:
        this.showBuyCard = true;
        break;
      case SubscriptionStatus.SUBSCRIBED:
        this.showQuitCard = true;
        break;
      case SubscriptionStatus.CANCELLED:
        this.showResubscribeCard = true;
        break;
    }
  }

  /**
   * is called when the user wants to close the modal, closes the modal and checks if data has changed, if so,
   * reloads the list of employees again to show the updated list of users
   * @param reload
   * @private
   */
  private async closeDialog(reload: boolean) {
    this.showBuyCard = false;
    this.showResubscribeCard = false;
    this.showQuitCard = false;
    this.showActiveStripe = false;
    if (reload) this.onBackPressed();
  }
}
